import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { IQuote } from "../interfaces/IQuote.interface";
import { quotes } from "../content/quotes";
import { RootState } from "../store";
import { IQuotesAsyncThunk } from "../interfaces/IQuotesAsyncThunk";
import { QuoteLanguageEnum } from "../enums/QuoteLanguageEnum.enum";
import { CategoryEnum } from "../enums/CategoryEnum.enum";

type quotesInitialState = {
    isLoading: boolean,
    allQuotes: IQuote[],
    bestQuotes: IQuote[],
    roadToSuccessQuotes: IQuote[],
    loveQuotes: IQuote[],
    inspirationalQuotes: IQuote[],
    friendshipQuotes: IQuote[],
    happinessQuotes: IQuote[],
    motivationalQuotes: IQuote[],
    leadershipQuotes: IQuote[],
    
    
}

const initialState: quotesInitialState = {
    isLoading: false,
    allQuotes:[],
    bestQuotes: [],
    roadToSuccessQuotes: [],
    loveQuotes: [],
    inspirationalQuotes: [],
    friendshipQuotes: [],
    happinessQuotes: [],
    motivationalQuotes: [],
    leadershipQuotes: [],

}

export const loadQuotes = createAsyncThunk('quotes/loadQuotes', async ({language}: IQuotesAsyncThunk,{ dispatch, getState }): Promise<any> => {
    const state = getState() as RootState;
    dispatch(clearAllQuotes());
    dispatch(clearBestQuotes());
    dispatch(clearRoadToSuccessQuotes());
    dispatch(clearLoveQuotes());
    dispatch(clearInspirationalQuotes());
    dispatch(clearFriendshipQuotes());
    dispatch(clearHappinessQuotes());
    dispatch(clearMotivationaluotes());
    dispatch(clearLeadershipQuotes());

    quotes?.filter(x => x.language == language).map(async(item, index) => {
        await dispatch(pushAllQuotes(item));
        if(item.category === CategoryEnum.BEST_QUOTES) {
            await dispatch(pushBestQuotes(item));
        } else if(item.category === CategoryEnum.ROAD_TO_SUCCESS) {
            await dispatch(pushRoadToSuccessQuotes(item));
        } else if(item.category === CategoryEnum.LOVE) {
            await dispatch(pushLoveQuotes(item));
        } else if(item.category === CategoryEnum.INSPIRATIONAL) {
            await dispatch(pushInspirationalQuotes(item));
        } else if(item.category === CategoryEnum.FRIENDSHIP) {
            await dispatch(pushFriedshipQuotes(item));
        } else if(item.category === CategoryEnum.HAPPINESS) {
            await dispatch(pushHappinessQuotes(item));
        } else if(item.category === CategoryEnum.MOTIVATIONAL) {
            await dispatch(pushMotivationalQuotes(item));
        } else if(item.category === CategoryEnum.LEADERSHIP) {
            await dispatch(pushLeadershiQuotes(item));
        }

    });
});

export const QuotesSlice = createSlice({
    name:"quotes",
    initialState:initialState,
    reducers: {
        pushAllQuotes:(state,action) => {
            state.allQuotes.push(action.payload);
        },
        clearAllQuotes:(state) => {
            state.allQuotes = [];
        },
        pushBestQuotes:(state,action) => {
            state.bestQuotes.push(action.payload);
        },
        clearBestQuotes:(state) => {
            state.bestQuotes = [];
        },  
        pushRoadToSuccessQuotes:(state,action) => {
            state.roadToSuccessQuotes.push(action.payload);
        },
        clearRoadToSuccessQuotes:(state) => {
            state.roadToSuccessQuotes = [];
        },
        pushLoveQuotes:(state,action) => {
            state.loveQuotes.push(action.payload);
        },
        clearLoveQuotes:(state) => {
            state.loveQuotes = [];
        },
        pushInspirationalQuotes:(state,action) => {
            state.inspirationalQuotes.push(action.payload);
        },
        clearInspirationalQuotes:(state) => {
            state.inspirationalQuotes = [];
        },  
        pushFriedshipQuotes:(state,action) => {
            state.friendshipQuotes.push(action.payload);
        },
        clearFriendshipQuotes:(state) => {
            state.friendshipQuotes = [];
        },
        pushHappinessQuotes:(state,action) => {
            state.happinessQuotes.push(action.payload);
        },
        clearHappinessQuotes:(state) => {
            state.happinessQuotes = [];
        },  
        pushMotivationalQuotes:(state,action) => {
            state.motivationalQuotes.push(action.payload);
        },
        clearMotivationaluotes:(state) => {
            state.motivationalQuotes = [];
        },  
        pushLeadershiQuotes:(state,action) => {
            state.leadershipQuotes.push(action.payload);
        },
        clearLeadershipQuotes:(state) => {
            state.leadershipQuotes = [];
        },       
    },
    extraReducers: builder => {
        builder
            .addCase(loadQuotes.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(loadQuotes.fulfilled, (state, action) => {
                state.isLoading = true;
            })
            .addCase(loadQuotes.rejected, (state,  { error }: any) => {
                state.isLoading = true;
            })
    }
})

export const QuotesReducer = QuotesSlice.reducer;

const baseInfo = (state: RootState) => state.quotes;

export const { pushAllQuotes,
    clearAllQuotes, 
    pushBestQuotes, 
    clearBestQuotes, 
    pushRoadToSuccessQuotes, 
    clearRoadToSuccessQuotes, 
    pushLoveQuotes, 
    clearLoveQuotes, 
    pushInspirationalQuotes, 
    clearInspirationalQuotes,
    pushFriedshipQuotes,
    clearFriendshipQuotes,
    pushHappinessQuotes,
    clearHappinessQuotes,
    pushMotivationalQuotes,
    clearLeadershipQuotes,
    pushLeadershiQuotes,
    clearMotivationaluotes
} = QuotesSlice.actions;

export const getQuotesState = createSelector(baseInfo, QuotesSlice => QuotesSlice);