import { IQuote } from "../interfaces/IQuote.interface";
import { CategoryEnum } from "../enums/CategoryEnum.enum";
import { QuoteLanguageEnum } from "../enums/QuoteLanguageEnum.enum";
import { AuthorsEnum } from "../enums/AuthorsEnum.enum";

export const quotes: IQuote[] = [
    { id: 1, quote: 'If you want to lift yourself up, lift up someone else', author: 'Booker T. Washington', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH },
    { id: 2, quote: 'Life is like riding a bicycle. To keep your balance, you must keep moving', author: 'Albert Einstein', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 3, quote: 'The purpose of our lives is to be happy', author: 'Dalai Lama', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 4, quote: 'The best revenge is massive success', author: 'Frank Sinatra', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 5, quote: 'Loved you yesterday, love you still, always have, always will', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 6, quote: 'जल्दी सोना और जल्दी उठना इंसान को स्वस्थ ,समृद्ध और बुद्धिमान बनाता है', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 7, quote: 'જાગ્યા ત્યાથી સવાર', author: 'Unknown', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.GUJARATI},
    { id: 8, quote: 'If it\'s good, it\'s wonderful. If it\'s bad, it\'s experience', author: 'Victoria Holt', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},

    { id: 9, quote: 'The way to get started is to quit talking and begin doing', author: 'Walt Disney', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 10, quote: 'If life were predictable it would cease to be life, and be without flavor', author: 'Eleanor Roosevelt', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 11, quote: 'If you look at what you have in life, you\'ll always have more. If you look at what you don\'t have in life, you\'ll never have enough', author: 'Oprah Winfrey', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 12, quote: 'If you set your goals ridiculously high and it\'s a failure, you will fail above everyone else\'s success', author: 'James Cameron', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 13, quote: 'Life is what happens when you\'re busy making other plans', author: 'John Lennon', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 14, quote: 'Spread love everywhere you go. Let no one ever come to you without leaving happier', author: 'Mother Teresa', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},

    { id: 15, quote: 'When you reach the end of your rope, tie a knot in it and hang on', author: 'Franklin D. Roosevelt', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 16, quote: 'Always remember that you are absolutely unique. Just like everyone else', author: 'Margaret Mead', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 17, quote: 'Don\'t judge each day by the harvest you reap but by the seeds that you plant', author: 'Robert Louis Stevenson', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 18, quote: 'The future belongs to those who believe in the beauty of their dreams', author: 'Eleanor Roosevelt', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 19, quote: 'Tell me and I forget. Teach me and I remember. Involve me and I learn', author: 'Benjamin Franklin', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 20, quote: 'The best and most beautiful things in the world cannot be seen or even touched — they must be felt with the heart', author: 'Helen Keller', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 21, quote: 'It is during our darkest moments that we must focus to see the light', author: 'Aristotle', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 22, quote: 'Whoever is happy will make others happy too', author: 'Anne Frank', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 23, quote: 'Do not go where the path may lead, go instead where there is no path and leave a trail', author: 'Ralph Waldo Emerson', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 24, quote: 'You will face many defeats in life, but never let yourself be defeated', author: 'Maya Angelou', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 25, quote: 'The only impossible journey is the one you never begin', author: 'Tony Robbins', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 26, quote: 'Only a life lived for others is a life worthwhile', author: 'Albert Einstein', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 27, quote: 'You only live once, but if you do it right, once is enough', author: 'Mae West', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 28, quote: 'Go confidently in the direction of your dreams! Live the life you\'ve imagined', author: 'Henry David Thoreau', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 29, quote: 'Life is ours to be spent, not to be saved', author: 'D. H. Lawrence', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 30, quote: 'Keep smiling, because life is a beautiful thing and there\'s so much to smile abou', author: 'Marilyn Monroe', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 31, quote: 'Life is a long lesson in humility', author: 'James M. Barrie', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 32, quote: 'In three words I can sum up everything I\'ve learned about life: it goes on', author: 'Robert Frost', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 33, quote: 'Love the life you live. Live the life you love', author: 'Bob Marley', category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 34, quote: 'Life is either a daring adventure or nothing at all', author: 'Helen Keller', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 35, quote: 'You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose', author: 'Dr. Seuss', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 36, quote: 'Life is made of ever so many partings welded together', author: 'Charles Dickens', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 37, quote: 'Life is trying things to see if they work', author: 'Ray Bradbury', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    { id: 38, quote: 'Many of life\'s failures are people who did not realize how close they were to success when they gave up', author: 'Thomas A. Edison', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    
    { id: 39, quote: 'Success is not final; failure is not fatal: It is the courage to continue that counts', author: 'Winston S. Churchill', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 40, quote: 'Success usually comes to those who are too busy to be looking for it', author: 'Henry David Thoreau', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 41, quote: 'The way to get started is to quit talking and begin doing', author: 'Walt Disney', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 42, quote: 'If you really look closely, most overnight successes took a long time', author: 'Steve Jobs', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 43, quote: 'The secret of success is to do the common thing uncommonly well', author: 'John D. Rockefeller', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 44, quote: 'I find that the harder I work, the more luck I seem to have', author: 'Thomas Jefferson', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 45, quote: 'Don\'t be distracted by criticism. Remember — the only taste of success some people get is to take a bite out of you', author: 'Zig Ziglar', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 46, quote: 'Success usually comes to those who are too busy to be looking for it', author: 'Henry David Thoreau', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 47, quote: 'I never dreamed about success, I worked for it', author: 'Estee Lauder', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 48, quote: 'Success seems to be connected with action. Successful people keep moving. They make mistakes but they don\'t quit', author: 'Conrad Hilton', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 49, quote: 'There are no secrets to success. It is the result of preparation, hard work, and learning from failure', author: 'Colin Powell', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 50, quote: 'The only limit to our realization of tomorrow will be our doubts of today', author: 'Franklin D. Roosevelt', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 51, quote: 'It is better to fail in originality than to succeed in imitation', author: 'Herman Melville', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 52, quote: 'Successful people do what unsuccessful people are not willing to do. Don\'t wish it were easier; wish you were better', author: 'Jim Rohn', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 53, quote: 'The road to success and the road to failure are almost exactly the same', author: 'Colin R. Davis', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 54, quote: 'I failed my way to success', author: 'Thomas Edison', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 55, quote: 'If you set your goals ridiculously high and it\'s a failure, you will fail above everyone else\'s success', author: 'James Cameron', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 56, quote: 'A successful man is one who can lay a firm foundation with the bricks others have thrown at him', author: 'David Brinkley', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 57, quote: 'Things work out best for those who make the best of how things work out', author: 'John Wooden', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 58, quote: 'Try not to become a man of success. Rather become a man of value', author: 'Albert Einstein', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 59, quote: 'Don\'t be afraid to give up the good to go for the great', author: 'John D. Rockefeller', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 60, quote: 'Success is walking from failure to failure with no loss of enthusiasm', author: 'Winston Churchill', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 61, quote: 'You know you are on the road to success if you would do your job and not be paid for it', author: 'Oprah Winfrey', category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.ENGLILSH},    
  
    { id: 62, quote: 'The greatest glory in living lies not in never falling, but in rising every time we fall', author: 'Nelson Mandela', category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.ENGLILSH},
    
    { id: 63, quote: 'The best thing to hold onto in life is each other', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 64, quote: 'I saw that you were perfect, and so I loved you. Then I saw that you were not perfect and I loved you even more', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 65, quote: 'Thinking of you keeps me awake. Dreaming of you keeps me asleep. Being with you keeps me alive', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 66, quote: 'I need you like a heart needs a beat', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 67, quote: 'If I know what love is, it is because of you', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 68, quote: 'I swear I couldn\'t love you more than I do right now, and yet I know I will tomorrow', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 69, quote: 'Women are meant to be loved, not to be understood', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 70, quote: 'You know you\'re in love when you can\'t fall asleep because reality is finally better than your dreams', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 71, quote: 'Love is like the wind, you can\'t see it but you can feel it', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 72, quote: 'To be your friend was all I ever wanted; to be your lover was all I ever dreamed.', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 73, quote: 'A purpose of human life, no matter who is controlling it, is to love whoever is around to be loved', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 74, quote: 'Romance is the glamour which turns the dust of everyday life into a golden haze', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 75, quote: 'One word frees us of all the weight and pain of life: that word is love', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 76, quote: 'Choose a job you love, and you will never have to work a day in your life', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 77, quote: 'Let the beauty of what you love be what you do', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 78, quote: 'Only true love can fuel the hard work that awaits you', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 79, quote: 'You, yourself, as much as anybody in the entire universe, deserve your love and affection', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 80, quote: 'The greatest thing you\'ll ever learn is to love and be loved in return', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 81, quote: 'Love all, trust a few, do wrong to none', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 82, quote: 'You call it madness, but I call it love', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 83, quote: 'A life lived in love will never be dull', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 84, quote: 'Life is the flower for which love is the honey', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 85, quote: 'Love is the ultimate expression of the will to live', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 86, quote: 'Love is what makes the ride worthwhile', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 87, quote: 'We love because it\'s the only true adventure', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 88, quote: 'Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 89, quote: 'Age does not protect you from love, but love to some extent protects you from age', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 90, quote: 'Life is the first gift, love is the second, and understanding the third', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},
    { id: 91, quote: 'Better to have loved and lost, than to have never loved at all', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.ENGLILSH},

    { id: 92, quote: 'If you believe it\’ll work out, you\’ll see opportunities. If you don\’t believe it\’ll work out, you\’ll see obstacles', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 93, quote: 'Keep your eyes on the stars, and your feet on the ground', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 94, quote: 'You can waste your lives drawing lines. Or you can live your life crossing them', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 95, quote: 'You\’ve got to get up every morning with determination if you\’re going to go to bed with satisfaction', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 96, quote: 'In a gentle way, you can shake the world', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 97, quote: 'If opportunity doesn\’t knock, build a door', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 98, quote: 'Don\’t be pushed around by the fears in your mind. Be led by the dreams in your heart', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 99, quote: 'Work hard in silence, let your success be the noise', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 100, quote: 'Hard work beats talent when talent doesn\’t work hard', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 101, quote: 'Opportunity is missed by most people because it is dressed in overalls and looks like work', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 102, quote: 'Never stop doing your best just because someone doesn\’t give you credit', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 103, quote: 'The miracle is not that we do this work, but that we are happy to do it', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 104, quote: 'Never give up on a dream just because of the time it will take to accomplish it. The time will pass anyway', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 105, quote: 'If you work on something a little bit every day, you end up with something that is massive', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 106, quote: 'The big secret in life is that there is no secret. Whatever your goal, you can get there if you\’re willing to work', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},

    { id: 107, quote: 'कोशिश हमेशा आखरी सांस तक करनी चाहिए ! या तो लक्ष्य हासिल होगा या अनुभव !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 108, quote: 'इंतेज़ार करने वालो को सिर्फ उतना मिलता है ! जितना कोशिश करने वाले छोड़ देते है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 109, quote: 'मेहनत इतनी ख़ामोशी से करो ! कि सफलता शोर मचा दें !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 110, quote: 'जितने वाले कुछ अलग चीज़े नहीं करते ! बस वो चीज़ो को अलग तरीके से करते हैं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 111, quote: 'आप तब तक नहीं हार सकतें ! जब तक आप कोशिश करना नहीं छोड़ देते !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 112, quote: 'किसी को हरा देना बहुत ही आसान है ! लेकिन किसी को जीतना बहुत ही मुश्किल !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 113, quote: 'जब तक किसी काम को किया नहीं जाता ! तब तक वह असंभव ही लगता है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 114, quote: 'यदि मंज़िल न मिले तो रास्ते बदलो ! क्योंकि वृक्ष अपनी पत्तियाँ बदलते हैं जड़े नहीं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 115, quote: 'पसंद है मुझे उन लोगों से हारना ! जो मेरे हारने की वजह से पहली बार जीते हैं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 116, quote: 'जीतने का असली मज़ा तो तब है ! जब सब आपके हारने का इंतज़ार कर रहे हो !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 117, quote: 'सफलता के लिए किसी भी ख़ास समय का इंतज़ार मत करो ! बल्कि अपने हर समय को ख़ास बनालो !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 118, quote: 'सफलता हमारा परिचय दुनिया को करवाती है ! और असफलता हमें दुनिया का परिचय करवाती है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 119, quote: 'अगर आपको हारने से डर लगता है तो ! जीतने की इच्छा कभी मत करना !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 120, quote: 'सफलता का मुख्य आधार ! सकारात्मक सोच और निरंतर प्रयास है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 121, quote: 'जो अपने क़दमों की काबिलियत पर विश्वास रखते हैं वही लोग अक्सर मंज़िल पर पहुंचते हैं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 122, quote: 'संघर्ष इंसान को मज़बूत बनाता है ! फिर चाहे वह कितना भी कमज़ोर क्यों न हो !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 123, quote: 'एक सपने के टूटकर चकनाचूर हो जाने के बाद ! दूसरा सपना देखने के हौसले को ज़िन्दगी कहते हैं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 124, quote: 'सपने वो नहीं जो आप सोते वक़्त देखें ! सपने वो होते है, जो आपको सोने नहीं दें !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 125, quote: 'सफलता का चिराग़ कठिन परिश्रम से ही जलता है !', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 126, quote: 'आशावादी हर आपत्तियों में भी अवसर देखता है ! और निराशावादी बहाने !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 127, quote: 'इससे पहले की सपने सच हो ! आपको सपने देखने होंगे !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 128, quote: 'हारना सबसे बुरी विफलता नहीं है ! कोशिश न करना ही सबसे बड़ी विफलता है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 129, quote: 'सफलता आप तक नहीं आएगी ! बल्कि आपको स्वयं उस तक जाना होगा !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 130, quote: 'गिरने पर भी हर बार उठ जाना और ! दुबारा कोशिश करना ही असली जीत है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 131, quote: 'कमज़ोर तब रूकते है, जब वे थक जाते हैं ! और विजेता तब रूकते जब वे जीत जाते हैं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 132, quote: 'इंतजार करना बंद करो ! क्योंकि सही समय कभी नहीं आता !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 133, quote: 'मिसाल क़ायम करने के लिए ! अपना रास्ता स्वयं बनाना होता है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.HINDI},
    { id: 134, quote: 'सिर्फ खड़े होकर पानी देखने से आप ! नदी पार नहीं कर सकतें !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 135, quote: 'महानता कभी न गिरने में नहीं है ! बल्कि हर बार गिर कर उठ जाने में है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 136, quote: 'बड़ा सोचो, जल्दी सोचो और आगे सोचो ! विचारों पर किसी का एकाधिकार नहीं है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 137, quote: 'जिसके पास धैर्य है, वह जो चाहे पा सकता है !', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 138, quote: 'अभी से वो होना शुरू कीजिये ! जो आप भविष्य में होंगे !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 139, quote: 'किस्मत मौका देती है ! लेकिन मेहनत सबको चौंका देती है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 140, quote: 'लाखो किलोमीटर की यात्रा ! एक कदम से ही शुरू होती है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.HINDI},
    { id: 141, quote: 'कितना भी पकड़लो फिसलता ज़रूर है ! ये वक़्त है ! जनाब बदलता ज़रूर है !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 142, quote: 'कोई भी लक्ष्य इंसान के संघर्ष से बड़ा नहीं ! हारा वही जो लड़ा नहीं !!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 143, quote: 'जिक्र उसी की होती है जिसकी फ़िक्र होती है!', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LOVE, language: QuoteLanguageEnum.HINDI},
    { id: 144, quote: 'प्रत्येक अच्छा कार्य पहले असम्भव नजर आता है।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.ROAD_TO_SUCCESS, language: QuoteLanguageEnum.HINDI},
    { id: 145, quote: 'ऊद्यम ही सफलता की कुंजी है।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 146, quote: 'सफलता अत्यधिक परिश्रम चाहती है।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 147, quote: 'महान सपने देखने वालों के महान सपने हमेशा पूरे होते हैं।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 148, quote: 'विपरीत परस्थितियों में कुछ लोग टूट जाते हैं , तो कुछ लोग लोग रिकॉर्ड तोड़ते हैं।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 149, quote: 'जितना कठिन संघर्ष होगा जीत उतनी ही शानदार होगी।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 150, quote: 'सफलता का कोई रहस्य नहीं है, वह केवल अत्यधिक परिश्रम चाहती है।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.HINDI},
    { id: 151, quote: 'वही सफल होता है, जिसका काम उसे निरन्तर आनन्द देता है ।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},
    { id: 152, quote: 'सफलता में दोषों को मिटाने की विलक्षण शक्ति है।', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.BEST_QUOTES, language: QuoteLanguageEnum.HINDI},

    { id: 153, quote: 'The bad news is time flies. The good news is you\'re the pilot', author: AuthorsEnum.UNKNOWN, category: 'Michael Altshuler', language: QuoteLanguageEnum.ENGLILSH},
    { id: 154, quote: 'Life has got all those twists and turns. You\'ve got to hold on tight and off you go.', author: 'Nicole Kidman', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 155, quote: 'Keep your face always toward the sunshine, and shadows will fall behind you.', author: 'Walt Whitman', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 156, quote: 'You define your own life. Don\'t let other people write your script.', author: 'Oprah Winfrey', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 157, quote: 'Do not allow people to dim your shine because they are blinded. Tell them to put some sunglasses on.', author: 'Lady Gaga', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 158, quote: 'You don\'t always need a plan. Sometimes you just need to breathe, trust, let go and see what happens.', author: 'Mandy Hale', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 159, quote: 'Belief creates the actual fact.', author: 'William James', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 160, quote: 'Believe you can and you\'re halfway there', author: 'Theodore Roosevelt', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 161, quote: 'In a gentle way, you can shake the world.', author: 'Mahatma Gandhi', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 162, quote: 'Try to be a rainbow in someone\'s cloud', author: 'Maya Angelou', category: CategoryEnum.INSPIRATIONAL, language: QuoteLanguageEnum.ENGLILSH},

    { id: 163, quote: 'A friend is someone who gives you total freedom to be yourself', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 164, quote: 'Good Friends Are Hard to Find, Difficult to Leave, Impossible to Forget', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 165, quote: 'Friends are like money in the bank, longer you keep them, the more they are worth', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 166, quote: 'Only a true best friend can protect you from your immortal enemies', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 167, quote: 'Friendship is the only cement that will hold the world together', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 168, quote: 'There is only one thing worse than fighting with allies and that is fighting without them', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 169, quote: 'There is nothing better than a friend unless it is a friend with chocolate', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 170, quote: 'Two things are important in our life. Friend friendship first one, you should make. Second one, you should keep', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 171, quote: 'Friends should be like books, easy to find when you need them, but seldom used', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 172, quote: 'The free conversation of a friend is what I would prefer to any environment', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.FRIENDSHIP, language: QuoteLanguageEnum.ENGLILSH},

    { id: 173, quote: 'Happiness is not a reward it is a consequence. Suffering is not a punishment it is a result', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 174, quote: 'Happiness is unrepentant pleasure', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 175, quote: 'Happiness is not about getting what you want all the time, its about appreciating what you already have', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 176, quote: 'Happiness is not a possession to be prized, it is a quality of thought, a state of mind', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 177, quote: 'I no longer try to be right; I choose to be happy', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 178, quote: 'Happiness is not a state to arrive at, rather, a manner of traveling', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 179, quote: 'Happiness is not a horse, you cannot harness it', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 180, quote: 'The key to happiness is to realize that you are in complete control of how happy you are', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 181, quote: 'Happiness is not pleasure, it is victory', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 182, quote: 'verybody wants happiness nobody wants pain but you cant have a rainbow without a little rain', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},
    { id: 183, quote: 'The richer you get, the more expensive happiness becomes', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.HAPPINESS, language: QuoteLanguageEnum.ENGLILSH},

    { id: 184, quote: 'If you want your life to be meaningful go out and do something about it', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 185, quote: 'Dont raise your voice, improve your argument', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 186, quote: 'A person who never made a mistake never tried anything new', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 187, quote: 'Everything is hard before it is easy', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 188, quote: 'Attitude is the mind\'s paintbrush; it can color any situation', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 189, quote: 'Whatever brings you down, will eventually make you stronger', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 190, quote: 'All power is within you,you can do anything and everything', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 191, quote: 'Excellence is not an act but a habit. The things you do the most are the things you will do best', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 192, quote: 'To achieve extra-ordinary results you must embrace change all the times', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},
    { id: 193, quote: 'Getting back is always easier than getting there', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.MOTIVATIONAL, language: QuoteLanguageEnum.ENGLILSH},

    { id: 194, quote: 'The man who follows a crowd will never be followed by a crowd', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 195, quote: 'A good leader takes a little more than his share of the blame, a little less than his share of the credit', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 196, quote: 'Today a reader, tomorrow a leader', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 197, quote: 'The only safe ship in a storm is leadership', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 198, quote: 'The speed of the leader determines the rate of the pack', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 199, quote: 'People who enjoy meetings should not be in charge of anything', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 200, quote: 'Don\'t find fault, find a remedy', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 201, quote: 'Character matters; leadership descends from character', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 202, quote: 'Education is the mother of leadership', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 203, quote: 'Leadership is a privilege to better the lives of others. It is not an opportunity to satisfy personal greed', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},
    { id: 204, quote: 'What you cannot enforce, do not command', author: AuthorsEnum.UNKNOWN, category: CategoryEnum.LEADERSHIP, language: QuoteLanguageEnum.ENGLILSH},

  ]