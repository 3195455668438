import React from "react";
import logo from '../../assets/images/logo.png';
import google_play_store from '../../assets/images/google-play-badge.png';

import './index.scss';

const Header = () => {
    return(
        <div className="row header-container">
            <div className="col-md-2">
                <div className="header-logo">
                    <img src={logo}  alt="www.qouts.in" width={120} height={120} />
                </div>      
            </div>
            <div className="col-md-6">
                <div style={{width:'100%'}}>

                </div>
            </div>
            <div className="col-md-4">
                <div className="googlePlaystoreButton">
                    <a href="https://play.google.com/store/apps/details?id=com.sourcetrim.quots" target="_blank" >
                        <img src={google_play_store} width={150} height={60} />
                    </a>
                </div>
            </div>
        </div>
        )
}

export default Header; 
