import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";

import './index.scss';

const QuoteOfTheDay = () => {
    const allQuotes = useAppSelector((state) => state.quotes.allQuotes);
    let random = Math.floor(Math.random() * (allQuotes.length - 1));
    let quote = allQuotes[random];

    return (
        <div className="row quoteOfTheDayContainer">
            <div className="col-md-3"></div>
            <div className="col-md-6 quoteOfTheDayItemContainer">                
                { quote && <> 
                    <div className="quoteOfTheDayCaption">
                        QUOTE OF THE DAY
                    </div>
                    <div className="quoteOfTheDayItem">                        
                            "{quote.quote}"
                    </div>
                    <div>
                        - {quote.author} -
                    </div> 
                </>
                }
            </div>
            <div className="col-md-3"></div>
        </div>
    )
}

export default QuoteOfTheDay;