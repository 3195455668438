import React from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../hooks";
import QuoteItem from "../../components/quoteItem";

import './index.scss';

const Home = () => {

    const isQuotesLoading = useAppSelector((state) => state.quotes.isLoading);
    const allQuotes = useAppSelector((state) => state.quotes.allQuotes);
    return(
        <div className="row">
            <div className="quotesContainer">
                {
                    allQuotes?.map((item, index) => {
                        return <QuoteItem key={index} quoteItem={item} />
                    })
                }
            </div>
        </div>
    )
}

export default Home;