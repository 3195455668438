import QuoteItem from "../../components/quoteItem";
import { useAppSelector } from "../../hooks";


const LeadersnipQuotes = () => {
    const leadershipQuotes = useAppSelector((state) => state.quotes.leadershipQuotes);

    return(
        <div className="row">
            <div className="quotesContainer">
                {
                    leadershipQuotes?.map((item, index) => {
                        return <QuoteItem key={index} quoteItem={item} />
                    })
                }
            </div>
        </div>
    )
}

export default LeadersnipQuotes;