import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import './index.scss';
import { SocialMediaLinks } from "../../enums/SocialMediaLink.enum";

type SocialSharingProps = {
    qoute: string,
    hashTag: string
}

const SocialSharing = ({qoute, hashTag}: SocialSharingProps) => {
    let hash = hashTag ? "#quotsdotin" + hashTag : "#quotsdotin";
    return(
        <div className="socialSharingContainer">
            <FacebookShareButton
                url={SocialMediaLinks.FACEBOOK}
                quote={qoute}
                hashtag={hash}
                style={{paddingRight:5}}                
                >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            
            <WhatsappShareButton
                url={SocialMediaLinks.TWITTER}
                about={qoute}                              
                style={{paddingRight:5}} 
                title={qoute}                                                                
                >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <TwitterShareButton
                url={SocialMediaLinks.TWITTER}
                about={qoute}                
                style={{paddingRight:5}}    
                title={qoute}            
                >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
        </div>
    )
}

export default SocialSharing;