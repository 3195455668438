import { CategoryEnum } from "../enums/CategoryEnum.enum";
import { ICategory } from "../interfaces/ICategory.interface";


export const Category: ICategory[] = [
    { id: 1, name: CategoryEnum.ALL, isActive: true, link: "/", colors: { inactiveColor:'#f7f1d2', activeColor:'#efda6b' } },
    { id: 2, name: CategoryEnum.BEST_QUOTES, isActive: true, link: "/bestquotes", colors: { inactiveColor:'#a3ffa3', activeColor:'#2eeb2e' } },
    { id: 3, name: CategoryEnum.ROAD_TO_SUCCESS, isActive: true, link: "/roadtosuccessquotes", colors: { inactiveColor:'#dfdeff', activeColor:'#8d89fb' } },
    { id: 4, name: CategoryEnum.LOVE, isActive: true, link: "/lovequotes", colors: { inactiveColor:'#fedcff', activeColor:'#f36ef7' } },
    { id: 5, name: CategoryEnum.INSPIRATIONAL, isActive: true, link: "/inspirationalquotes", colors: { inactiveColor:'#d2f2f7', activeColor:'#6ee4f7' } },
    { id: 6, name: CategoryEnum.FRIENDSHIP, isActive: true, link: "/friendshipquotes", colors: { inactiveColor:'#fbffde', activeColor:'#e4ff04' } },
    { id: 7, name: CategoryEnum.HAPPINESS, isActive: true, link: "/happinessquotes", colors: { inactiveColor:'#97e2ff', activeColor:'#00b8ff' } },
    { id: 9, name: CategoryEnum.MOTIVATIONAL, isActive: true, link: "/motivationalquotes", colors: { inactiveColor:'#ffeac4', activeColor:'#ffa500' } },
    { id: 8, name: CategoryEnum.LEADERSHIP, isActive: true, link: "/leadershipquotes", colors: { inactiveColor:'#ffdecf', activeColor:'#ff5305' } },
]