import QuoteItem from "../../components/quoteItem";
import { useAppSelector } from "../../hooks";


const HappinessQuotes = () => {
    const happinessQuotes = useAppSelector((state) => state.quotes.happinessQuotes);

    return(
        <div className="row">
            <div className="quotesContainer">
                {
                    happinessQuotes?.map((item, index) => {
                        return <QuoteItem key={index} quoteItem={item} />
                    })
                }
            </div>
        </div>
    )
}

export default HappinessQuotes;