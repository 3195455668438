import { IQuote } from "../../interfaces/IQuote.interface";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'


import SocialSharing from "../socialSharing";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './index.scss';
import { useState } from "react";

type quotesParam = {
    quoteItem: IQuote
}

const QuoteItem = ({quoteItem}: quotesParam) => {

    const [isCopied, setIsCopied] = useState(false);
    
    return (
        <div className="col-md-4 quotesItemContainer">
            <div className="quotes">
                <div className="quotesCaption">
                    {quoteItem.quote}
                </div>
                {
                    quoteItem.author && <div className="quotesAuthor">
                        - {quoteItem.author}
                    </div>
                }
                {/* share on social media */}
                <div className="quotesShareContainer">
                    <SocialSharing qoute={quoteItem.quote} hashTag="" />
                    <div className="quotesCopyContainer">
                        <CopyToClipboard text={quoteItem.quote}
                            onCopy={() => setIsCopied(true)}
                        >
                            <div style={{cursor:'pointer'}}>
                                <FontAwesomeIcon icon={faCopy} size='lg'/>
                            </div>
                        </CopyToClipboard>
                        {/* {isCopied ? <span style={{color: 'green'}}>Copied.</span> : null} */}
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default QuoteItem;