import React, { useState } from 'react';

import './index.scss';
import { CategoryEnum } from '../../enums/CategoryEnum.enum';
import { Category } from '../../content/category';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const MenuTabs = () => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(CategoryEnum.ALL)

    const onSelectedItem = (item: any) => {        
        setSelectedCategory(item.name);
        navigate(item.link);
    }

    return(
        <div className='row col-md-12 col-sm-12 menuTabsContainer'>
            {
                Category.filter(x => x.isActive && x.name != CategoryEnum.ALL).map((item,index) => {
                    return(
                        <div
                            key={index}     
                            className='tabsContainer'                                                    
                            onClick={() => onSelectedItem(item)}
                            style={{backgroundColor: (item.name == selectedCategory) ? item.colors?.activeColor : item.colors?.inactiveColor}}
                            >
                            <div className='tabs'>
                                {
                                    item.name === selectedCategory &&
                                    <div style={{paddingRight:2}}>
                                        <FontAwesomeIcon icon={faCheck} style={{color: "#000000",}} />
                                    </div>
                                }
                                {item.name}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    ) 
}

export default MenuTabs;