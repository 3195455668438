import React from 'react';
import { Route, Router, Routes } from "react-router-dom";
import Home from './pages/home';
import BestQuotes from './pages/bestQuotes';
import RoadToSuccess from './pages/roadToSuccess';
import LoveQuotes from './pages/LoveQuotes';
import InspirationalQuotes from './pages/inspirationalQuotes';
import FriendshipQuotes from './pages/friedshipQuotes';
import HappinessQuotes from './pages/happinessQuotes';
import MotivationalQuotes from './pages/motivationalQuotes';
import LeadersnipQuotes from './pages/leadershipQuotes';


const AppNavigator = () => {
    return (
        <Routes>           
            <Route path='/' element={<Home />} />
            <Route path='/bestquotes' element={<BestQuotes />} />
            <Route path='/roadtosuccessquotes' element={<RoadToSuccess />} />
            <Route path='/lovequotes' element={<LoveQuotes />} />
            <Route path='/inspirationalquotes' element={<InspirationalQuotes />} />
            <Route path='/friendshipquotes' element={<FriendshipQuotes />} />
            <Route path='/happinessquotes' element={<HappinessQuotes />} />
            <Route path='/motivationalquotes' element={<MotivationalQuotes />} />
            <Route path='/leadershipquotes' element={<LeadersnipQuotes />} />
        </Routes>
    )
}

export default AppNavigator;