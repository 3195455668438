
export enum CategoryEnum {
    ALL = "All",
    INSPIRATIONAL = "Inspirational",
    LOVE = "Love",
    BEST_QUOTES = "Best Quotes",  
    ROAD_TO_SUCCESS = "Road to Success",
    FRIENDSHIP = "Friendship",
    HAPPINESS = "Happiness",
    MOTIVATIONAL = "Motivational",
    STRENGTH = "Strength",
    WISDOM = "Wisdom",
    LEADERSHIP = "Leadership",
    ATTITUDE = "Attitude"    
}