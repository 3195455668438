import React, { useEffect } from 'react';
import './App.scss';
import Header from './components/header';
import SideNav from './components/sidenav';
import AppNavigator from './appNavigator';
import { loadQuotes } from './slices/quotesSlice';
import { useAppDispatch } from './hooks';
import { QuoteLanguageEnum } from './enums/QuoteLanguageEnum.enum';
import QuoteOfTheDay from './components/quoteoftheday';
import MenuTabs from './components/menuTabs';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(loadQuotes({language: QuoteLanguageEnum.ENGLILSH}));
    })();
  },[])
   
  return (
    <div className='container-fluid'>
      <div className="container">
        {/* header */}
        <Header />
        {/* quote of the day */}
        <QuoteOfTheDay />
        
        {/* tabs */}
        <MenuTabs />

        <div className="row">
            <div className="col-md-12">
                <AppNavigator />
        </div>                
        </div>
      </div>
    </div>
  );
}

export default App;
